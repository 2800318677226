import React, { useState, useEffect } from 'react';
import imgLogo from '../../assets/logo-votacao.png';
import './Cadastro.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { registration } from '../../services/Api';
import BigButton from '../../Componentes/BigButton/BigButton';
import InputMask from 'react-input-mask';
import { ValidateCpf } from '../../utils/ValidateCpf';
import Swal from 'sweetalert2';

function Cadastro() {
	const [turnstileToken, setTurnstileToken] = useState(null);

	useEffect(() => {
		const loadTurnstileScript = () => {
			const script = document.createElement('script');
			script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);
		};

		loadTurnstileScript();

		window.setTurnstileToken = (token) => {
			setTurnstileToken(token);
		};
	}, []);

	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		nome: '',
		email: '',
		celular: '',
		cpf: '',
	});

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	const showMessage = (text) => {
		Swal.fire({
			text: text,
			icon: 'error',
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!turnstileToken) {
			showMessage('Por favor, complete a verificação de segurança.');
			return;
		}

		if (formData.nome.length < 3) {
			showMessage('Nome inválido.');
			return;
		}
		if (!formData.email.includes('@')) {
			showMessage('E-mail inválido.');
			return;
		}
		if (formData.celular.length < 15) {
			showMessage('Celular inválido.');
			return;
		}
		if (formData.cpf.length < 14 || !ValidateCpf(formData.cpf)) {
			showMessage('CPF inválido.');
			return;
		}

		const data = {
			name: formData.nome,
			mail: formData.email,
			document: formData.cpf.replaceAll(/[ -]/g, ''),
			cellphone: formData.celular.replaceAll(/[.-]/g, ''),
			turnstileToken,
		};

		try {
			const response = await registration(data);
			if (response.status === 201) {
				navigate('/login');
			}
		} catch (error) {
			console.log(error);
			if (error.response.status === 409) {
				showMessage('Usuário já existe.');
				return;
			}
			if (error.response.status === 400) {
				showMessage('Usuário já encerrou votação.');
				return;
			}
			if (error.response.status === 401) {
				showMessage('Erro na validação de segurança.');
				return;
			}
			showMessage('Erro ao realizar cadastro, verifique seus dados...');
		}
	};

	const handleGoBack = () => {
		navigate('/login');
	};

	return (
		<div class="container text-center col-lg-8 col-md-10 col-sm-10">
			<div className="row justify-content-between px-2 mt-4">
				<div class="card card-principal col-lg-12 col-md-12 col-sm-12 shadow-lg">
					<div class="card-body col-12 justify-content-center">
						<div className="row justify-content-start mt-0 mb-0">
							<FaArrowLeft className="back-arrow-btn" onClick={handleGoBack} />
						</div>
						<div className="row justify-content-center">
							<img src={imgLogo} alt="logo-destaque" className="img-logo-destaque" />
						</div>
						<h2 className="">Crie a sua conta.</h2>
						<form onSubmit={handleSubmit}>
							<div className="mt-3">
								<input
									type="text"
									placeholder="Nome Completo"
									name="nome"
									value={formData.nome}
									onChange={handleChange}
									className="cadastro-input"
								/>
								<input
									type="email"
									placeholder="E-mail"
									name="email"
									value={formData.email}
									onChange={handleChange}
									className="cadastro-input"
								/>
								<InputMask mask="(99) 99999-9999" value={formData.celular} onChange={handleChange}>
									{(inputProps) => (
										<input
											{...inputProps}
											type="text"
											id="celular"
											className="cadastro-input"
											placeholder="Celular"
											name="celular"
										/>
									)}
								</InputMask>
								<InputMask mask="999.999.999-99" value={formData.cpf} onChange={handleChange}>
									{(inputProps) => (
										<input
											{...inputProps}
											type="text"
											id="cpf"
											name="cpf"
											className="cpf-input"
											placeholder="CPF"
										/>
									)}
								</InputMask>
							</div>
							<div
								className="cf-turnstile"
								data-sitekey="0x4AAAAAAA0y_D5Om_YuuA0i"
								data-callback="setTurnstileToken"
							></div>

							<BigButton type="submit" className="login-button mb-4">
								CADASTRAR
							</BigButton>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Cadastro;
